// import * as dotenv from "dotenv";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './React/Views/App';
import reportWebVitals from './reportWebVitals';
import {configure} from "mobx";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './React/Views/App.scss'
import * as path from "path";

// Load environment variables from .env files
// dotenv.config({path: path.join(__dirname, "../.env")});
// dotenv.config();

// MobX configuration
configure({
    enforceActions: "always",
    isolateGlobalState: true // necessary due to the zoom sdk using redux
});

// Render application
ReactDOM.render(
    <App/>
    ,
    document.getElementById('App')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
