import React, {FC} from "react";
import {observer} from "mobx-react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useHistory, withRouter} from "react-router-dom";
import {config} from "../../Config/Config";

interface Props {

}

const QuestionSelector: FC<Props> = observer(({}) => {
    const {t} = useTranslation();
    const history = useHistory();

    return <React.Fragment>
        <div className="selection-container">
            <h1 className="text-light">{t('selectQuestions')}</h1>
            <p className="text-secondary">{t('shuffleInfo')}</p>
            <Button variant="primary" onClick={() => history.push(config.paths.si)}>
                {t('questions') + " SI"}
            </Button>
        </div>
        <div className="selection-container mt-5">
            <Button variant="primary" className="o-50" onClick={() => history.push(config.paths.eie4)}>
                {t('questions') + " EIE: 4"}
            </Button>
            <Button variant="primary" className="o-50" onClick={() => history.push(config.paths.eie567)}>
                {t('questions') + " EIE: 5, 6 y 7"}
            </Button>
            <Button variant="primary" className="o-50" onClick={() => history.push(config.paths.eie)}>
                {t('questions') + " EIE: 4, 5, 6 y 7"}
            </Button>
            <Button variant="primary" className="o-50" onClick={() => history.push(config.paths.sr)}>
                {t('questions') + " SR"}
            </Button>
            <Button variant="primary" className="o-50" onClick={() => history.push(config.paths.sor)}>
                {t('questions') + " SOR"}
            </Button>
            <Button variant="primary" className="o-50" onClick={() => history.push(config.paths.all)}>
                {t('questions') + " " + t('forAll')}: SI + SOR
            </Button>
        </div>
    </React.Fragment>
});

export default withRouter(QuestionSelector);