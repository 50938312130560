export const config = {
    paths: {
        eie: "/eie",
        eie4: "/eie4",
        eie567: "/eie567",
        si: "/si",
        sr: "/sr",
        sor: "/sor",
        all: "/all"
    }
};