import React, {FC, useEffect, useState} from "react"
import {observer} from "mobx-react";
import {Button, Card, Col, Container, Form, Pagination, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {observable, runInAction} from "mobx";
import {QuestionRating} from "../../MobX/Question";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {useHistory, withRouter} from "react-router-dom";

interface Props {
    questions: any[];
    subject?: string;
}

const QuizView: FC<Props> = observer(({questions, subject=""}) => {
    const [page, setPage] = useState<number>(1);
    const [userAnswers] = useState<Map<number, string[]>>(() => observable.map(new Map()));
    const [validatedQuestions] = useState<number[]>(() => observable.array([]));
    const [jumpToPage, setJumpToPage] = useState<number>(1);
    const [userInputs] = useState<Map<string, string>>(() => observable.map(new Map()));
    let optionCount: number = 0;
    let answerCount: number = 0;
    const {t} = useTranslation();
    const history = useHistory();

    function maxPages(): number {
        return Math.max(1, Math.ceil(questions.length));
    }

    function hasMultipleCorrect(): boolean {
        return Object.entries(questions[page-1].options)
            .filter(([option, solution]) => solution == QuestionRating.Correct)
            .length > 1;
    }

    useEffect(() => {
        // console.log(userAnswers);
    }, [userAnswers.size]);

    useEffect(() => {
        optionCount = 0;
    }, [page]);

    return <div className="middle-container">
        <h1 className="text-light">{t('questions')} {subject}</h1>
        <p className="text-secondary">{t('shuffleInfo')}</p>
        <div className="question-view">
            <Card className="pb-0">
                <Card.Body>
                    <Card.Title>{questions[page-1].question}</Card.Title>
                    {
                        questions[page - 1].img != undefined &&
                        <img alt={t('questionRelatedImg')} src={questions[page - 1].img}/>
                    }
                    <Form noValidate>
                        {
                            // Text input
                            questions[page - 1].answer != undefined &&
                            <React.Fragment>
                                <Form.Control as="textarea"
                                              rows={5}
                                              onChange={e => {
                                                  runInAction(() => {
                                                      userInputs.set(questions[page - 1].question, e.target.value);
                                                  });
                                              }}
                                              value={userInputs.get(questions[page - 1].question) || ""}/>
                                {
                                    // Simple answer
                                    typeof questions[page - 1].answer === "string" &&
                                    validatedQuestions.includes(page) &&
                                    <React.Fragment>
                                        <p className="font-weight-bold mb-0 mt-1">{t('answer')}:</p>
                                        <p>{questions[page - 1].answer}</p>
                                    </React.Fragment>
                                }
                                {
                                    // Multiple answers
                                    typeof questions[page - 1].answer !== "string" &&
                                    validatedQuestions.includes(page) &&
                                    <React.Fragment>
                                        <p className="font-weight-bold mb-0 mt-1">{t('answers')}:</p>
                                        <ul>
                                            {
                                                questions[page - 1].answer.map((answer: string) => {
                                                    return <li key={answerCount++}>{answer}</li>
                                                })
                                            }
                                        </ul>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                        {
                            // Multiple-Choice
                            questions[page - 1].answer == undefined &&
                            Object.entries(questions[page-1].options).map(([option, solution]) => {
                                let optionId = optionCount;
                                optionCount++;

                                return <div className="d-block" key={optionId}>
                                    <Form.Check type={hasMultipleCorrect() ? "checkbox" : "radio"}
                                                name={page.toString()}
                                                className={
                                                    "d-inline-block " + (
                                                        // User selected correct
                                                        validatedQuestions.includes(page) &&
                                                        solution == QuestionRating.Correct &&
                                                        (userAnswers.get(page) || []).includes(option)
                                                            ? "text-success"
                                                            : (
                                                                // User selected wrong
                                                                validatedQuestions.includes(page) &&
                                                                solution == QuestionRating.Wrong &&
                                                                (userAnswers.get(page) || []).includes(option)
                                                                    ? "text-danger"
                                                                    : (
                                                                        // Display where to find correct
                                                                        validatedQuestions.includes(page) &&
                                                                        solution == QuestionRating.Correct &&
                                                                        !(userAnswers.get(page) || []).includes(option)
                                                                            ? "text-success"
                                                                            : (
                                                                                // Display where to find maybe
                                                                                validatedQuestions.includes(page) &&
                                                                                solution == QuestionRating.Maybe &&
                                                                                !(userAnswers.get(page) || []).includes(option)
                                                                                    ? "text-secondary"
                                                                                    : ""
                                                                            )
                                                                    )
                                                            )
                                                    )
                                                }
                                                label={option}
                                                checked={
                                                    userAnswers.get(page)?.includes(option)
                                                }
                                                onChange={() => {
                                                    runInAction(() => {
                                                        // First answer selected, init list
                                                        if(userAnswers.get(page) == null) {
                                                            userAnswers.set(page, []);
                                                        }
                                                        // Checkbox
                                                        if(hasMultipleCorrect()) {
                                                            // Selecting the same options twice => remove it from list
                                                            if (userAnswers.get(page)?.includes(option)) {
                                                                let optionIndex = userAnswers.get(page)?.indexOf(option);
                                                                if (optionIndex != undefined && optionIndex >= 0) {
                                                                    userAnswers.get(page)!.splice(
                                                                        optionIndex,
                                                                        1
                                                                    );
                                                                }
                                                            }
                                                            // Add new option
                                                            else {
                                                                userAnswers.get(page)?.push(option);
                                                            }
                                                        }
                                                        // Radio button
                                                        else {
                                                            // Empty list and add option
                                                            userAnswers.set(page, [option]);
                                                        }
                                                    });
                                                }}
                                                id={optionId.toString()}/>
                                </div>
                            })
                        }
                        <div className="d-block mt-3 mb-0" >
                            <Button onClick={() => setPage(Math.max(1, page - 1))}>
                                <FontAwesomeIcon icon={faChevronLeft}/>
                            </Button>
                            <Button className="ml-1 mr-1" onClick={() => {
                                if(!validatedQuestions.includes(page)) {
                                    runInAction(() => validatedQuestions.push(page));
                                }
                            }}>
                                {t('checkSolution')}
                            </Button>
                            <Button onClick={() => setPage(Math.min(maxPages(), page + 1))}>
                                <FontAwesomeIcon icon={faChevronRight}/>
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </div>

        {/* Pagination controls */}
        <Container className="w-800px">
            <Row>
                <Col md="auto" className="align-self-center p-0">
                    <Pagination className="align-self-center align-items-center mb-0" size="sm">
                        <Pagination.First onClick={() => setPage(1)}/>
                        <Pagination.Prev onClick={() => setPage(Math.max(1, page - 1))}/>
                        <Pagination.Item active={page === 1} onClick={() => setPage(1)}>1</Pagination.Item>

                        {
                            // Current page is in an inner page
                            <React.Fragment>
                                {page > 3 && <Pagination.Ellipsis/>}
                                {page > 2 && maxPages() > 1 && <Pagination.Item
                                    onClick={() => setPage(Math.max(1, page - 1))}>{page - 1}</Pagination.Item>}
                                {page !== 1 && page !== maxPages() &&
                                <Pagination.Item active>{page}</Pagination.Item>}
                                {page < maxPages() - 3 && <Pagination.Item
                                    onClick={() => setPage(Math.min(maxPages(), page + 1))}>{page + 1}</Pagination.Item>}
                                {page < maxPages() - 2 && <Pagination.Ellipsis/>}
                            </React.Fragment>
                        }

                        {
                            maxPages() > 1 &&
                            <Pagination.Item active={page === maxPages()} onClick={() => setPage(maxPages())}>
                                {maxPages()}
                            </Pagination.Item>
                        }
                        <Pagination.Next onClick={() => setPage(Math.min(maxPages(), page + 1))}/>
                        <Pagination.Last onClick={() => setPage(maxPages())}/>
                    </Pagination>
                </Col>

                <Col md="auto" className="ml-4 p-0 align-self-center text-light">{t('jumpToPage')}:</Col>
                <Col md="auto" className="ml-2 p-0 align-self-center">
                    <Form.Control size="sm" type="number" value={jumpToPage || ""} onChange={e => {
                        const newPage = Number(e.target.value.toString().trim());
                        if (newPage >= 0 && newPage <= maxPages()) {
                            setJumpToPage(newPage);
                        }
                    }} placeholder={t('page')}/>
                </Col>
                <Col md="auto" className="ml-2 p-0 align-self-center">
                    <Button size="sm" variant="primary" onClick={() => {
                        if (jumpToPage > 0) {
                            setPage(jumpToPage);
                        }
                        else {
                            setPage(1);
                            setJumpToPage(1);
                        }
                    }}>
                        {t('go')}
                        <FontAwesomeIcon className="ml-2" icon={faChevronRight}/>
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col md="auto" className="ml-0 p-0 mt-2">
                    <Button variant="secondary" onClick={() => history.push("/")}>
                        {t('goMainMenu')}
                    </Button>
                </Col>
            </Row>
        </Container>
    </div>
});

export default QuizView;