import React, {FC} from 'react';
import {observer} from "mobx-react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import i18n from "i18next";
import {initReactI18next, useTranslation} from "react-i18next";
import {resources} from "../../Config/translation";
import QuizView from "./QuizView";
import {shuffle} from "../../Utils";
import QuestionSelector from "./QuestionSelector";
import {config} from "../../Config/Config";
import QuestionsEIE4 from "../../Config/Questions/EIE4.json";
import QuestionsEIE567 from "../../Config/Questions/EIE567.json";
import QuestionsSI from "../../Config/Questions/SI.json";
import QuestionsSR from "../../Config/Questions/SR.json";
import QuestionsSOR from "../../Config/Questions/SOR.json";

// i18n configuration
i18n.use(initReactI18next).init({
    lng: 'en',
    resources
});

const App: FC = observer(({}) => {
    const {t} = useTranslation();

    function mapEntries(entry: any) {
        let question: any = {
            question: entry.question,
            options: Object.fromEntries(shuffle(Object.entries(entry.options || []))),
            answer: undefined,
            img: undefined
        };
        if(entry.answer) {
            question.answer = entry.answer;
        }
        if(entry.img) {
            question.img = "https://study.jkuklok.net/images/" + entry.img
        }
        return question;
    }

    return <BrowserRouter>
        <Switch>
            <Route path={config.paths.sr}>
                <QuizView questions={shuffle(QuestionsSR.map(mapEntries))} subject="SR"/>
            </Route>
            <Route path={config.paths.eie567}>
                <QuizView questions={shuffle(QuestionsEIE567.map(mapEntries))} subject="EIE: 5, 6 y 7"/>
            </Route>
            <Route path={config.paths.eie4}>
                <QuizView questions={shuffle(QuestionsEIE4.map(mapEntries))} subject="EIE: 4"/>
            </Route>
            <Route path={config.paths.eie}>
                <QuizView questions={shuffle([...QuestionsEIE4, ...QuestionsEIE567].map(mapEntries))} subject="EIE: 4, 5, 6 y 7"/>
            </Route>
            <Route path={config.paths.si}>
                <QuizView questions={shuffle(QuestionsSI.map(mapEntries))} subject="SI"/>
            </Route>
            <Route path={config.paths.sor}>
                <QuizView questions={shuffle(QuestionsSOR.map(mapEntries))} subject="SOR"/>
            </Route>
            <Route path={config.paths.all}>
                <QuizView questions={shuffle([...QuestionsSI, ...QuestionsSOR, ...QuestionsEIE4, ...QuestionsEIE567, ...QuestionsSR].map(mapEntries))}
                          subject={" " + t('forAll')}/>
            </Route>
            <Route path="/">
                <QuestionSelector/>
            </Route>
        </Switch>
    </BrowserRouter>
});

export default App;